import React, {useState, useEffect} from 'react';
import {Input, Button, Modal, Form, Row, Col, notification, Select, Alert, Switch, Card} from 'antd';
import Picker from "emoji-picker-react";
import {
  createWhatsappTemplateService,
  getWhatsappTemplateService,
  updateWhatsappTemplateService
} from 'services/dashboard/WhatsappService';
import {WhatsappTemplateCreateResponse} from 'models/responses';
import TextArea from "antd/lib/input/TextArea";
import {InfoCircleOutlined, MinusOutlined, PlusOutlined} from "@ant-design/icons";
import languageOptions from './language';
import ChatPreviewPage from "../WhatsappTemplatePreview";
import {
  WhatsappTemplateCreateRequest,
  WhatsappTemplateUpdateRequest
} from "../../../../models/requests";
import {WhatsappTemplateDetails} from "../../../../models";

export interface WhatsappTemplateProps {
  showWhatsappTemplateForm: boolean
  onSave?: (response: WhatsappTemplateCreateResponse, isNew: boolean) => void,
  onClose?: () => void,
  editItem?: WhatsappTemplateDetails;
}

const WhatsappTemplateForm = (props: WhatsappTemplateProps) => {
    const [form] = Form.useForm();
    const [currentStep, setCurrentStep] = useState(1);
    const {showWhatsappTemplateForm, editItem, onClose, onSave} = props;
    const [loading, setLoading] = useState(false);
    const [headerType, setHeaderType] = useState<string>('');
    const [variableCount, setVariableCount] = useState(0);
    // const [variableCountBody, setVariableCountBody] = useState(0);
    const [headerText, setHeaderText] = useState('');
    const [headerFormat, setHeaderFormat] = useState('');
    const [bodyText, setBodyText] = useState('');
    const [footerText, setFooterText] = useState('');
    const [formData, setFormData] = useState({});
    const [buttonType, setButtonType] = useState('');
    const [errorMessage] = useState('');
    const [actionType, setActionType] = useState('');
    const [exampleHeaderValues, setExampleHeaderValues] = useState<any>({});
    const [exampleBodyValues, setExampleBodyValues] = useState<any>({});
    const [buttonClicked, setButtonClicked] = useState(false);
    const [buttonTextValues, setButtonTextValues] = useState<string[]>([]);
    const [buttonPhoneTextValues, setButtonPhoneTextValues] = useState<string[]>([]);
    const [buttonUrlTextValues, setButtonUrlTextValues] = useState<string[]>([]);
    const [fieldsAction, setFieldsAction] = useState([{key: 'initial_key'}]);
    const [selectionStart, setSelectionStart] = useState(0);
    const [selectionEnd, setSelectionEnd] = useState(0);
    const [onSelect, setOnSelect] = useState(false);
    const [showEmojiPicker, setShowEmojiPicker] = useState(false);


    const resetAndCloseForm = () => {
      form.resetFields();
      onClose?.();
    };
    const showErrorNotification = (message: string) => {
      const notificationParam = {
        message,
        description: ''
      };
      notification.error(notificationParam);
    };
    const showSuccessNotification = (message: string) => {
      const notificationParam = {
        message,
        description: ''
      };
      notification.success(notificationParam);
    };
    const onOkModel = () => {
      if (!loading) {
        resetAndCloseForm();
        setButtonTextValues([]);
        setButtonPhoneTextValues([]);
        setButtonUrlTextValues([]);
      }
    };

    const onCancelModel = () => {
      if (!loading) {
        resetAndCloseForm();
        setCurrentStep(1);
        resetAndCloseForm();
        setHeaderType('');
        setHeaderText('');
        setHeaderFormat('');
        setBodyText('');
        setExampleHeaderValues({});
        setExampleBodyValues({});
        setFooterText('');
        setButtonType('');
        setButtonClicked(false);
        resetAndCloseForm();
        setButtonType('');
        setActionType('');
        setButtonTextValues([]);
        setButtonPhoneTextValues([]);
        setButtonUrlTextValues([]);
      }
    };

    const onSuccessSaveWhatsappTemplate = (response: WhatsappTemplateCreateResponse) => {
      let type = 'created';
      let isNew = true;
      if (editItem) {
        type = 'updated';
        isNew = false;
      }
      showSuccessNotification(`WhatsappTemplate ${type}`);
      setLoading(false);
      setCurrentStep(1);
      resetAndCloseForm();
      setHeaderType('');
      setHeaderText('');
      setHeaderFormat('');
      setBodyText('');
      setExampleHeaderValues({});
      setExampleBodyValues({});
      setFooterText('');
      setButtonType('');
      setButtonClicked(false);
      setButtonType('');
      setActionType('');
      setButtonTextValues([]);
      setButtonPhoneTextValues([]);
      setButtonUrlTextValues([]);
      onSave?.(response, isNew);

    };

    const onErrorSaveWhatsappTemplate = (message: string) => {
      showErrorNotification(message);
      setLoading(false);
    };

    const updateWhatsappTemplate = async (templateId: number, templateRequest: WhatsappTemplateUpdateRequest) => {
      setLoading(true);
      try {
        const response = await updateWhatsappTemplateService(templateRequest, templateId);
        if (response.id) {
          setLoading(false);
          onSuccessSaveWhatsappTemplate(response);
        } else {
          setLoading(false);
          onErrorSaveWhatsappTemplate("Failed to save Whatsapp Template");
        }
      } catch (error: any) {
        setLoading(false);
        onErrorSaveWhatsappTemplate(error.message);
      }
    };
    const createWhatsappTemplate = async (templateRequest: WhatsappTemplateCreateRequest) => {
      setLoading(true);
      try {
        const response = await createWhatsappTemplateService(templateRequest);
        if (response.id) {
          setLoading(false);
          onSuccessSaveWhatsappTemplate(response);
        } else {
          setLoading(false);
          onErrorSaveWhatsappTemplate("Failed to save whatsapp template!");
        }
      } catch (error: any) {
        setLoading(false);
        onErrorSaveWhatsappTemplate(error.message);
      }
    };

    const checkWhatsappTemplate = async (templateName: string, templateLanguage: string) => {
      setLoading(true);
      try {
        const response = await getWhatsappTemplateService(templateName, {"language": templateLanguage});
        if (response) {
          onErrorSaveWhatsappTemplate("Template Name already exists!");
          setCurrentStep(1);
        } else {
          setCurrentStep(currentStep + 1);
        }
        setLoading(false);
      } catch (error: any) {
        setLoading(false);
        onErrorSaveWhatsappTemplate(error.message);
      }
    };

    const onSubmitForm = async (values: {
      "waba_id": string,
      "template_name": string,
      "template_language": string[],
      "category": string,
      allow_category_change: boolean,
      "header_text": any,
      "header_format": string,
      "media_url": string,
      "example_header_text": any,
      "body_text": any,
      "example_body_text": any,
      "body_add_security_recommendation": false,
      "footer_text": string,
      "code_expiration_minutes": number,
      "message_send_ttl_seconds": number,
      "buttons": {
        "buttons_type": string,
        "button_quick_reply": {
          "button_text_quick_reply": string
        },
        "button_phone_number": {
          "button_text_phone_number": string,
          "button_phone_number": string
        },
        "button_url": {
          "button_text_url": string,
          "button_url": string,
          "example_url_values": string
        },
        "button_Otp": {
          "button_test_otp": string,
          "otp_type": string,
          "otp_autofill_text": string,
          "otp_package_name": string,
          "otp_signature_hash": string,
        }
      }
    }) => {
      setLoading(true);
      try {
        let WhatsappButtons;
        if (values.buttons) {
          let buttonsType: any;
          if (buttonType === 'QUICK_REPLY') {
            buttonsType = ['QUICK_REPLY'];
          } else if (buttonType === 'None') {
            buttonsType = null;
          } else if (actionType === 'PHONE_NUMBER') {
            buttonsType = ['PHONE_NUMBER'];
          } else if (actionType === 'URL') {
            buttonsType = ['URL'];
          } else if (actionType === 'PHONE_NUMBER_AND_URL') {
            buttonsType = ['URL', 'PHONE_NUMBER'];
          }
          WhatsappButtons = {
            buttons_type: buttonsType || null,
            button_quick_reply: values.buttons && values.buttons.button_quick_reply ? values.buttons.button_quick_reply : null,
            button_phone_number: values.buttons && values.buttons.button_phone_number ? values.buttons.button_phone_number : null,
            button_url: values.buttons && values.buttons.button_url ? values.buttons.button_url : null,
            button_Otp: values.buttons && values.buttons.button_Otp ? values.buttons.button_Otp : null
          };
        }

        const exampleHeaderTextArray: any = exampleHeaderValues || null;
        const exampleBodyTextArray: any = exampleBodyValues || null;

        const updatedValues = {
          ...values,
          header_text: headerText || null,
          header_format: values.header_format === 'None' ? null : values.header_format,
          media_url: values.header_format ? values.media_url || null : null,
          example_header_text: exampleHeaderValues ? exampleHeaderTextArray : null,
          body_text: bodyText || null,
          example_body_text: exampleBodyValues ? exampleBodyTextArray : null,
          body_add_security_recommendation: values.body_add_security_recommendation,
          footer_text: values.footer_text || null,
          code_expiration_minutes: values.code_expiration_minutes || null,
          message_send_ttl_seconds: values.message_send_ttl_seconds || null,
          buttons: WhatsappButtons
        };

        const createRequest = {
          waba_id: values.waba_id,
          template_name: values.template_name,
          template_language: values.template_language,
          allow_category_change: values.allow_category_change,
          category: values.category,
          header_text: headerText || null,
          header_format: values.header_format === 'None' ? null : values.header_format,
          media_url: values.header_format ? values.media_url || null : null,
          example_header_text: exampleHeaderValues ? exampleHeaderTextArray : null,
          body_text: bodyText || null,
          example_body_text: exampleBodyValues ? exampleBodyTextArray : null,
          body_add_security_recommendation: values.body_add_security_recommendation,
          footer_text: values.footer_text || null,
          code_expiration_minutes: values.code_expiration_minutes || null,
          message_send_ttl_seconds: values.message_send_ttl_seconds || null,
          buttons: WhatsappButtons,
        };

        if (editItem) {
          await updateWhatsappTemplate(editItem?.id ?? '', updatedValues);
        } else {
          await createWhatsappTemplate(createRequest);
        }
      } catch (error: any) {
        //
      }
    };


    const onNextStep = () => {
      form.validateFields()
        .then(values => {
          const newFormData = {...formData, ...values};

          if (currentStep < 3) {
            if (currentStep === 1 && !editItem) {
              checkWhatsappTemplate(values.template_name, values.template_language);
            } else {
              setCurrentStep(currentStep + 1);
            }
            setFormData(newFormData);
          } else {
            onSubmitForm(newFormData);
          }
        });
    };

    const handleHeaderTypeChange = (value: any) => {
      setHeaderType(value);
      if (value === 'TEXT') {
        setHeaderFormat('');
      }
    };

    const handleAddVariable = () => {
      if (headerText) {
        if (headerText.includes('{{0}}')) {
          // If '{{0}}' is already present, do not add another one.
          setVariableCount(0);
          return;
        }
      }


      const newVariableIndex = variableCount.toString();
      setHeaderText((prevText) => `${prevText}{{${variableCount}}}`);

      setExampleHeaderValues((prevValues: any) => ({
        ...prevValues,
        [newVariableIndex]: '',
      }));
      if (headerText) {
        if (headerText.includes('{{0}}')) {
          setButtonClicked(true);
        }
      }
    };

    // const handleAddVariableToBody = () => {
    //   const newBodyVariableIndex = variableCountBody.toString();
    //   setBodyText((prevTextBody) => `${prevTextBody}{{${variableCountBody}}}`);
    //   setVariableCountBody((prevCountBody) => prevCountBody + 1);
    //
    //   setExampleBodyValues((prevValues) => ({
    //     ...prevValues,
    //     [newBodyVariableIndex]: '',
    //   }));
    // };

    const handleAddVariableToBody = () => {
      const bodyVariables = bodyText.match(/{{(\d+)}}/g);
      const lastVariableCount = bodyVariables
        ? Math.max(...bodyVariables.map((match: any) => parseInt(match.match(/{{(\d+)}}/)[1], 10)))
        : -1;
      const newBodyVariableIndex = (lastVariableCount + 1).toString();

      setBodyText((prevTextBody) => `${prevTextBody}{{${newBodyVariableIndex}}}`);

      setExampleBodyValues((prevValues: any) => ({
        ...prevValues,
        [newBodyVariableIndex]: '',
      }));
    };


    const ButtonTypeChange = (value: string) => {
      setButtonType(value);
    };

    const ActionTypeChange = (value: any) => {
      setActionType(value);
    };

    useEffect(() => {

      if (editItem) {
        form.setFieldsValue({
          template_name: editItem.template_name,
          template_language: editItem.template_language,
          category: editItem.category,
          header_text: editItem.header_text,
          header_format: editItem.header_format,
          media_url: editItem.media_url,
          example_header_text: editItem.example_header_text,
          body_text: editItem.body_text,
          example_body_text: editItem.example_body_text,
          body_add_security_recommendation: editItem.body_add_security_recommendation,
          footer_text: editItem.footer_text,
          buttons: editItem.buttons
        });

        setHeaderType(editItem.header_format);
        setHeaderFormat(editItem.header_format);
        setHeaderText(editItem.header_text);
        setBodyText(editItem.body_text);
        setExampleHeaderValues(editItem.example_header_text || {});
        setExampleBodyValues(editItem.example_body_text || {});
        setFooterText(editItem.footer_text);
        // setButtonType(editItem.buttons?.buttons_type || '');
        if (editItem.header_format === 'IMAGE' ||editItem.header_format === 'VIDEO' ||editItem.header_format === 'LOCATION' ||editItem.header_format === 'DOCUMENT') {
          setHeaderType('Select Media Type');
          setHeaderFormat(editItem.header_format);
          setHeaderText('');
        } else {
          setHeaderType(editItem.header_format);
          setHeaderFormat('');
          setHeaderText(editItem.header_text);
        }

        if (editItem.buttons?.button_quick_reply) {
          setButtonType('QUICK_REPLY');
          const buttonTextEditValue: string[] = editItem.buttons?.button_quick_reply.map((button: any) => button.button_text_quick_reply);
          setButtonTextValues(buttonTextEditValue);
        }
        if (editItem.buttons?.button_phone_number) {
          setActionType('PHONE_NUMBER');
          setButtonType('CALL_TO_ACTION');
          const buttonTextEditPhoneValues: string[] = editItem.buttons?.button_phone_number.map((button: any) => button.button_text_phone_number);
          setButtonPhoneTextValues(buttonTextEditPhoneValues);
        }
        if (editItem.buttons?.button_url) {
          setActionType('URL');
          setButtonType('CALL_TO_ACTION');
          const buttonTextEditUrlValues: string[] = editItem.buttons?.button_url.map((button: any) => button.button_text_url);
          setButtonUrlTextValues(buttonTextEditUrlValues);
        }
        if (editItem.buttons?.button_url && editItem.buttons?.button_phone_number) {
          setActionType('PHONE_NUMBER_AND_URL');
          setButtonType('CALL_TO_ACTION');
          const buttonTextEditUrlValues: string[] = editItem.buttons?.button_url.map((button: any) => button.button_text_url);
          const buttonTextEditPhoneValues: string[] = editItem.buttons?.button_phone_number.map((button: any) => button.button_text_phone_number);
          setButtonPhoneTextValues(buttonTextEditPhoneValues);
          setButtonUrlTextValues(buttonTextEditUrlValues);
        }
      }


      if (form.getFieldValue('body_text')) {
        setBodyText(form.getFieldValue('body_text'));
      }
    }, [editItem]);

    const applyFormatting = (format: any) => {
      if (onSelect && (selectionStart !== selectionEnd) ){
        let startSymbol = '';
        let endSymbol = '';

        switch (format) {
          case 'bold':
            startSymbol = '*';
            endSymbol = '*';
            break;
          case 'italic':
            startSymbol = '_';
            endSymbol = '_';
            break;
          case 'code':
            startSymbol = '```';
            endSymbol = '```';
            break;
          default:
            break;
        }

        setBodyText((prevText) => {
          const textBeforeSelection = prevText.slice(0, selectionStart);
          const selectedText = prevText.slice(selectionStart, selectionEnd);
          const textAfterSelection = prevText.slice(selectionEnd);
          return `${textBeforeSelection}${startSymbol}${selectedText}${endSymbol}${textAfterSelection}`;
        });
      }
    };


  const handleTextAreaSelection = (e: any) => {
    setSelectionStart(e.target.selectionStart);
    setSelectionEnd(e.target.selectionEnd);
    setOnSelect(true);
  };



    const handleEmojiClick = (emojiObject: any) => {
      const {emoji} = emojiObject;
      const newText = `${bodyText.slice(0, selectionStart)}${emoji}${bodyText.slice(selectionEnd)}`;
      setBodyText(newText);
      setShowEmojiPicker(false);
    };

    const chatContainerStyle: React.CSSProperties = {
      margin: '20px auto',
      padding: '10px',
      boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)',
      background: `url('https://user-images.githubusercontent.com/15075759/28719144-86dc0f70-73b1-11e7-911d-60d70fcded21.png')`, // Replace with your image URL
      backgroundSize: 'cover',
      backgroundPosition: 'center',
      position: 'relative',
    };

    const handleButtonTextChange = (index: any, value: any) => {
      const newButtonTextValues = [...buttonTextValues];
      newButtonTextValues[index] = value;
      setButtonTextValues(newButtonTextValues);
    };

    const handleButtonPhoneTextChange = (index: any, value: any) => {
      const newButtonPhoneTextValues = [...buttonPhoneTextValues];
      newButtonPhoneTextValues[index] = value;
      setButtonPhoneTextValues(newButtonPhoneTextValues);
    };

    const handleButtonUrlTextChange = (index: any, value: any) => {
      const newButtonUrlTextValues = [...buttonUrlTextValues];
      newButtonUrlTextValues[index] = value;
      setButtonUrlTextValues(newButtonUrlTextValues);
    };

    const addField = () => {
      const newField = {key: `new_key_${fieldsAction.length}`};
      setFieldsAction([...fieldsAction, newField]);
    };

    const removeField = (index: number) => {
      const newFields = [...fieldsAction];
      newFields.splice(index, 1);
      setFieldsAction(newFields);

      const newPhoneTextValues = [...buttonPhoneTextValues];
      newPhoneTextValues.splice(index, 1);
      setButtonPhoneTextValues(newPhoneTextValues);

      const newUrlTextValues = [...buttonUrlTextValues];
      newUrlTextValues.splice(index, 1);
      setButtonUrlTextValues(newUrlTextValues);
    };

    return (
      <Modal
        title={editItem ? "Update Whatsapp template" : "Add Whatsapp template"}
        visible={showWhatsappTemplateForm}
        onOk={onOkModel}
        onCancel={onCancelModel}
        footer={null}
        width="75%"

      >
        <div style={{marginBottom: '9px', fontSize: '14px', color: 'indianred', textAlign: 'right'}}>
          Step {currentStep} of 3
        </div>
        <Row>
          <Col xs={18} sm={18} md={18}>
            <Form
              name="whatsappTemplateForm"
              layout="horizontal"
              size="small"
              form={form}
              onFinish={onSubmitForm}
            >
              {currentStep === 1 && (
                <Row gutter={16}>
                  <Col xs={24} sm={24} md={24}>
                    <Form.Item
                      label="Name"
                      name="template_name"
                      labelCol={{span: 3}}
                      labelAlign='left'
                      rules={[
                        {
                          required: true,
                          message: 'Please enter template name',
                        },
                        {
                          validator: (_, value) => {
                            if (value && value !== value.toLowerCase()) {
                              return Promise.reject(new Error('Only lowercase letters are supported.'));
                            }
                            return Promise.resolve();
                          },
                        },
                        {
                          pattern: /^[a-z0-9_]+$/,
                          message: 'Template name should contain lowercase alphabets, underscores, and numbers only',
                        },
                        {
                          max: 512,
                          message: 'template name cannot exceed 512 characters',
                        },
                      ]}
                    >
                      <Input disabled={!!editItem}/>
                    </Form.Item>
                  </Col>
                  <Col xs={24} sm={12} md={12}>
                    <Form.Item
                      label="Category"
                      name="category"
                      labelCol={{span: 6}}
                      labelAlign='left'
                      rules={[
                        {
                          required: true,
                          message: 'Please select Template Category',
                        }
                      ]}
                    >
                      <Select placeholder="Select a Category" disabled={!!editItem}>
                        {/* <Select.Option value="AUTHENTICATION">Authentication</Select.Option> */}
                        <Select.Option value="MARKETING">Marketing</Select.Option>
                        <Select.Option value="UTILITY">Utility</Select.Option>
                      </Select>
                    </Form.Item>
                  </Col>
                  <Col xs={24} sm={12} md={12}>
                    <Form.Item
                      label={
                        <span>
                          Allow Category Change
                          <InfoCircleOutlined
                            style={{fontSize: '16px', marginLeft: '8px', cursor: 'pointer'}}
                            onClick={() => {
                              Modal.info({
                                title: "Allow Category Change",
                                content: (
                                  <>
                                    <div>
                                      You can include the <b>Allow category change</b> to <b>true</b> to categorize your
                                      template based on its content. This can prevent your template status from
                                      immediately being set to REJECTED.
                                    </div>
                                    <br/>
                                    <div style={{opacity: '0.5'}}>
                                      If there is a mismatch between the designated category and the category we
                                      determine based on your template content, the template will be created but its
                                      status will be set to REJECTED
                                    </div>
                                  </>
                                ),
                              });
                            }}
                          />
                      </span>
                      }
                      name="allow_category_change"
                      labelCol={{span: 9}}
                      labelAlign='left'
                      valuePropName="checked"
                      getValueProps={(value) => ({checked: value})}
                      getValueFromEvent={(checked) => checked}
                    >
                      <Switch disabled={!!editItem}/>
                    </Form.Item>
                  </Col>
                  <Col xs={24} sm={24} md={24}>
                    <Form.Item
                      label="Language"
                      name="template_language"
                      labelCol={{span: 3}}
                      labelAlign="left"
                      rules={[
                        {
                          required: true,
                          message: 'Please select a Language',
                        },
                      ]}
                    >
                      <Select
                        placeholder="Select a Language"
                        showSearch
                        optionFilterProp="children"
                        filterOption={(input, option: any) =>
                          option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                        }
                        disabled={!!editItem}>
                        {Object.keys(languageOptions).map(code => (
                          <Select.Option key={code} value={code}>
                            {languageOptions[code]}
                          </Select.Option>
                        ))}
                      </Select>
                    </Form.Item>
                  </Col>

                </Row>)}
              {currentStep === 2 && (
                <Row gutter={16}>
                  <Col xs={24} sm={24} md={24}>
                    <h4>
                      Header <small style={{color: 'gray', fontSize: '12px'}}> (Optional)</small>
                    </h4>
                  </Col>

                  <Col xs={24} sm={12} md={12}>
                    <Form.Item
                      name="header_format"
                      labelCol={{span: 3}}
                      labelAlign="right"
                    >
                      <Select
                        placeholder="Select a Header"
                        onChange={handleHeaderTypeChange}
                      >
                        <Select.Option value="TEXT">Text</Select.Option>
                        <Select.Option value="Select Media Type">Media</Select.Option>
                        <Select.Option hidden value="IMAGE">Media</Select.Option>
                        <Select.Option hidden value="VIDEO">Media</Select.Option>
                        <Select.Option hidden value="DOCUMENT">Media</Select.Option>
                        <Select.Option hidden value="LOCATION">Media</Select.Option>
                        <Select.Option value="None">None</Select.Option>
                      </Select>
                    </Form.Item>
                  </Col>
                  <Col xs={24} sm={12} md={12}>
                    {headerType === 'TEXT' && (
                      <Form.Item
                        name="header_text"
                        labelCol={{span: 3}}
                        labelAlign="right"
                        extra="Maximum length is 60 chars"
                        rules={[
                          {
                            max: 60,
                            message: 'Header text cannot exceed 60 characters',
                          },
                          {
                            validator: (_, value) => {
                              if (value && (value.match(/{{\d+}}/g) || []).length > 1) {
                                return Promise.reject(new Error('Only one variable (like {{0}}) is allowed.'));
                              }
                              return Promise.resolve();
                            },
                          },
                          {
                            validator: (_, value) => {
                              const variableRegex = /{{\d+}}/g;
                              const variableMatches = value.match(variableRegex);

                              if (!variableMatches) {
                                // If there are no variables, it's valid.
                                return Promise.resolve();
                              }

                              if (variableMatches.length === 1 && variableMatches[0] === '{{0}}') {
                                // If there is exactly one variable and it's '{{0}}', it's valid.
                                return Promise.resolve();
                              }

                              // If there are other variables or '{{0}}' is not the only variable, show an error.
                              return Promise.reject(new Error('Only {{0}} is allowed.'));
                            },
                          }
                        ]}
                      >
                        <div style={{display: 'flex', alignItems: 'center'}}>
                          <Input
                            placeholder="Enter header text"
                            value={headerText}
                            onChange={(e) => setHeaderText(e.target.value)}
                          />
                          <Button
                            onClick={handleAddVariable}
                            disabled={buttonClicked}
                            style={{
                              border: 'none',
                              background: 'transparent',
                              marginLeft: '8px',
                            }}
                          >
                            Add Variable +
                          </Button>
                        </div>
                      </Form.Item>
                    )}
                    {headerType === 'Select Media Type' && (
                      <Form.Item
                        name="header_format"
                        labelCol={{span: 3}}
                        labelAlign="right"
                        // initialValue="IMAGE"
                      >
                        <Select placeholder="Select media type" value={headerFormat}
                                onChange={value => setHeaderFormat(value)}>
                          <Select.Option value="IMAGE">Image</Select.Option>
                          <Select.Option value="VIDEO">Video</Select.Option>
                          <Select.Option value="DOCUMENT">Document</Select.Option>
                          <Select.Option value="LOCATION">Location</Select.Option>
                        </Select>
                      </Form.Item>
                    )}
                  </Col>
                  <Col xs={24} sm={24} md={24}>
                    {headerFormat && ["IMAGE", "VIDEO", "DOCUMENT"].includes(headerFormat) && (
                      <Form.Item
                        name="media_url"
                        // labelCol={{ span: 3 }}
                        labelAlign="right"
                        label="Media URL"
                        rules={[
                          {
                            required: true,
                            message: 'Please add url for you media',
                          },
                        ]}
                      >
                        <Input placeholder="Enter media URL"/>
                      </Form.Item>
                    )}
                  </Col>
                  <Col xs={24} sm={24} md={24}>
                    {/* Example Data Input for Header Text Variables */}
                    {headerType === 'TEXT' && headerText && headerText.includes('{{') && (
                      <Card
                        title={
                          <div style={{display: 'flex', alignItems: 'center'}}>
                            Samples for Header Content
                            <InfoCircleOutlined
                              style={{fontSize: '18px', marginLeft: '8px'}}
                              onClick={() => {
                                Modal.info({
                                  title: "Variable Example Information",
                                  content: (
                                    <div>
                                      To help us review your message template,
                                      please add an example for each variable in
                                      your header text. Do not use real customer
                                      information.
                                    </div>
                                  ),
                                });
                              }}
                            />
                          </div>
                        }
                        style={{
                          backgroundColor: 'lightyellow',
                          marginBottom: '16px',
                          textAlign: 'center'
                        }}
                      >
                        <Form.Item
                          name="example_header_text"
                          labelCol={{span: 3}}
                          labelAlign="right"
                          /* eslint-disable react/no-array-index-key */>
                          {headerText && headerText.split('{{').map((part, index) => {
                            if (part && part.includes('}}')) {
                              const variableIndex: any = part.split('}}')[0];
                              return (
                                <Input
                                  key={index}
                                  name={`example_header_text[${variableIndex}]`}
                                  placeholder={`Example value for {{${variableIndex}}}`}
                                  style={{marginBottom: '8px'}}
                                  value={exampleHeaderValues[variableIndex]}
                                  onChange={(e) => {
                                    const newExampleValues = {...exampleHeaderValues};
                                    newExampleValues[variableIndex] = e.target.value;
                                    setExampleHeaderValues(newExampleValues);
                                  }}
                                />
                              );
                            }
                            return null;
                          })}
                        </Form.Item>
                      </Card>
                    )}
                  </Col>

                  <Col xs={24} sm={24} md={24}>
                    <h4>
                      Body
                    </h4>
                  </Col>

                  <Col xs={24} sm={24} md={24}>
                    <Form.Item
                      className="mb-0"
                      name="body_text"
                      labelAlign='left'
                      extra="Maximum length is 1024 chars"
                      labelCol={{span: 3}}
                      rules={[
                        {
                          required: true,
                          message: 'Please enter Body text',
                        },
                        {
                          max: 1024,
                          message: 'Body text cannot exceed 1024 characters',
                        },
                        // {
                        //   validator: (rule, value) => {
                        //     if (value && (value.toLowerCase().includes('code') || value.toLowerCase().includes('otp'))) {
                        //       return Promise.reject(new Error('Body text should not contain authentication related keywords like: code, otp, etc'));
                        //     }
                        //     return Promise.resolve();
                        //   },
                        // },
                        {
                          validator: (_, value) => {
                            const variableRegex = /{{\d+}}/g;
                            const variableMatches = value ? value.match(variableRegex) : [];

                            if (!variableMatches || variableMatches.length === 0) {
                              // If there are no variables, it's valid.
                              return Promise.resolve();
                            }

                            const variableIndexes = variableMatches.map((match: any) =>
                              parseInt(match.match(/\d+/)[0], 10)
                            );

                            // Check if the variables start from {{0}} and are sequential.
                            let valid = true;
                            for (let i = 0; i < variableIndexes.length; i += 1) {
                              if (variableIndexes[i] !== i) {
                                valid = false;
                                break; // Exit the loop early if an invalid index is found.
                              }
                            }

                            if (!valid) {
                              return Promise.reject(new Error('Variables must start from {{0}} and be sequential.'));
                            }
                            return Promise.resolve();
                          },
                        }
                      ]}
                    >
                      <div style={{display: 'flex', alignItems: 'center'}}>
                        <TextArea
                          value={bodyText}
                          onSelect={handleTextAreaSelection}
                          onChange={(e) => setBodyText(e.target.value)}
                          /* eslint-disable react/no-array-index-key */
                          style={{height: '150px'}}/>
                        <Button
                          onClick={handleAddVariableToBody}
                          style={{
                            border: 'none',
                            background: 'transparent',
                            marginLeft: '8px',
                          }}
                        >
                          Add Variable +
                        </Button>
                      </div>
                    </Form.Item>
                    <div style={{textAlign: 'right', marginRight: '125px'}}>
                      <Button className="pl-3" type='text' onClick={() => applyFormatting('bold')}>
                        <b>B</b>
                      </Button>
                      <Button className="pl-3" type='text' onClick={() => applyFormatting('italic')}>
                        <b>I</b>
                      </Button>
                      <Button className="pl-3" type='text' onClick={() => applyFormatting('code')}>
                        <b>{`</>`}</b>
                      </Button>
                      <Button
                        className="pl-3"
                        type='text'
                        onClick={() => setShowEmojiPicker(!showEmojiPicker)}
                      >
                        😃
                      </Button>
                      {showEmojiPicker && (
                        <div style={{position: 'absolute', bottom: '30px', right: '20px'}}>
                          <Picker onEmojiClick={handleEmojiClick}/>
                        </div>
                      )}
                    </div>
                  </Col>

                  <Col xs={24} sm={24} md={24}>
                    {/* Example Data Input for Body Text Variables */}
                    {bodyText && bodyText.includes('{{') && (
                      <Card
                        title={
                          <div style={{display: 'flex', alignItems: 'center'}}>
                            Samples for Body Content
                            <InfoCircleOutlined
                              style={{fontSize: '18px', marginLeft: '8px'}}
                              onClick={() => {
                                // Show info content (You can use a modal or tooltip)
                                Modal.info({
                                  title: "Variable Example Information",
                                  content: (
                                    <div>
                                      To help us review your message template,
                                      please add an example for each variable in
                                      your body text. Do not use real customer
                                      information.
                                    </div>
                                  ),
                                });
                              }}
                            />
                          </div>
                        }
                        style={{
                          backgroundColor: 'lightgray',
                          marginBottom: '16px',
                          textAlign: 'center',
                          opacity: "70%"
                        }}
                      >
                        <Form.Item
                          name="example_body_text"
                          labelAlign="left"
                          labelCol={{span: 3}}
                        >
                          {bodyText && bodyText.split('{{').map((part, index) => {
                            if (part && part.includes('}}')) {
                              const variableIndex: any = part.split('}}')[0];
                              return (
                                <Input
                                  key={index}
                                  name={`example_body_text[${variableIndex}]`}
                                  placeholder={`Example value for {{${variableIndex}}}`}
                                  style={{marginBottom: '8px'}}
                                  value={exampleBodyValues[variableIndex]}
                                  onChange={(e) => {
                                    const newExampleValues = {...exampleBodyValues};
                                    newExampleValues[variableIndex] = e.target.value;
                                    setExampleBodyValues(newExampleValues);
                                  }}
                                />
                              );
                            }
                            return null;
                          })}
                        </Form.Item>
                      </Card>
                    )}
                  </Col>

                  <Col xs={24} sm={24} md={24}>
                    <h4>
                      Footer <small style={{color: 'gray', fontSize: '12px'}}> (Optional)</small>
                    </h4>
                  </Col>

                  <Col xs={24} sm={24} md={24}>
                    <Form.Item
                      name="footer_text"
                      labelAlign='left'
                      labelCol={{span: 3}}
                      extra="Maximum length is 60 chars"
                      rules={[
                        {
                          max: 60,
                          message: 'Footer text cannot exceed 60 characters',
                        },
                      ]}
                    >
                      <Input value={footerText}
                             onChange={(e) => setFooterText(e.target.value)}/>
                    </Form.Item>
                  </Col>

                </Row>
              )}

              {currentStep === 3 && (
                <Row gutter={24}>
                  <Col xs={24} sm={24} md={24}>
                    <p style={{textAlign: 'left'}}>
                      If you want any button in the template, choose one of them. Otherwise, Save and
                      Continue:
                    </p>
                  </Col>

                  <Col xs={24} sm={24} md={24}>
                    <Form.Item labelAlign='left' labelCol={{span: 3}} style={{textAlign: 'center'}}>
                      <Button.Group>
                        <Button
                          type={buttonType === 'QUICK_REPLY' ? 'primary' : 'default'}
                          onClick={() => ButtonTypeChange('QUICK_REPLY')}
                        >
                          Quick Replies
                        </Button>
                        <Button
                          type={buttonType === 'CALL_TO_ACTION' ? 'primary' : 'default'}
                          onClick={() => ButtonTypeChange('CALL_TO_ACTION')}
                        >
                          Call to Action
                        </Button>
                        <Button
                          type={buttonType === 'None' ? 'primary' : 'default'}
                          onClick={() => ButtonTypeChange('None')}
                        >
                          None
                        </Button>
                      </Button.Group>
                    </Form.Item>
                  </Col>


                  <Col xs={24} sm={24} md={24}>
                    {buttonType === 'None' && (
                      <Form.Item labelAlign='left' labelCol={{span: 3}} label="">
                        <Form.Item labelCol={{span: 3}} label="" name={['buttons', 'buttons_type']}>
                          <Input type="hidden" value="None"/>
                        </Form.Item>
                      </Form.Item>
                    )}
                  </Col>
                  <Col xs={24} sm={24} md={24}>
                    {buttonType === 'QUICK_REPLY' && (
                      <Form.Item labelAlign='left' labelCol={{span: 3}} label="">
                        <Form.List name={['buttons', 'button_quick_reply']}
                                   initialValue={['']} // Initialize with an empty string
                        >
                          {(fields, {add, remove}) => (
                            <>
                              {fields.map((field, index) => (
                                <div key={field.key}>
                                  <Form.Item
                                    labelAlign='left'
                                    labelCol={{span: 3}}
                                    label="Button Text"
                                    name={[field.name, 'button_text_quick_reply']}
                                    fieldKey={[field.fieldKey, 'button_text_quick_reply']}
                                    rules={[
                                      {
                                        required: true,
                                        message: 'Please enter Button text',
                                      },
                                      {
                                        max: 25,
                                        message: 'Body text cannot exceed 25 characters',
                                      }
                                    ]}
                                  >
                                    <Input placeholder="Enter button text"
                                           value={buttonTextValues[index] || ''}
                                           onChange={(e) => handleButtonTextChange(index, e.target.value)}/>
                                  </Form.Item>
                                  {index > 0 && (
                                    <Button
                                      style={{marginBottom: '12px'}}
                                      type="dashed"
                                      onClick={() => {
                                        remove(field.name);
                                        // Update state here to remove the corresponding button text
                                        const updatedButtonTextValues = [...buttonTextValues];
                                        updatedButtonTextValues.splice(index, 1);
                                        setButtonTextValues(updatedButtonTextValues);
                                      }}
                                      block
                                      icon={<MinusOutlined/>}
                                    >
                                      Remove Button
                                    </Button>
                                  )}
                                </div>
                              ))}
                              {fields.length < 3 && (
                                <Button type="dashed" onClick={() => add()} block icon={<PlusOutlined/>}>
                                  Add Button
                                </Button>
                              )}
                            </>
                          )}
                        </Form.List>
                        {errorMessage && <Alert message={errorMessage} type="error" showIcon/>}
                      </Form.Item>
                    )}
                  </Col>


                  {buttonType === 'CALL_TO_ACTION' && (
                    <Col xs={24} sm={24} md={24}>
                      <Form.Item labelAlign='left' labelCol={{span: 5}} label="Select Action Type"
                                 name={['buttons', 'buttons_type']}>
                        <Select placeholder="Select Action type"
                                onChange={(value) => ActionTypeChange(value)}>
                          <Select.Option value="PHONE_NUMBER">Call phone number</Select.Option>
                          <Select.Option value="URL">Website</Select.Option>
                          <Select.Option value="PHONE_NUMBER_AND_URL">Call phone number & Website</Select.Option>
                        </Select>
                      </Form.Item>

                      <Col>
                        {actionType === 'PHONE_NUMBER_AND_URL' && (
                          <>
                            {fieldsAction.map((field, index) => (
                              <div key={field.key}>
                                <Form.Item
                                  labelAlign='left'
                                  labelCol={{span: 5}}
                                  label="Button Text (Phone Number)"
                                  name={['buttons', 'button_phone_number', index, 'button_text_phone_number']}
                                  rules={[
                                    {
                                      required: true,
                                      message: 'Please enter Button text for Phone Number',
                                    },
                                    {
                                      max: 25,
                                      message: 'Body text cannot exceed 25 characters',
                                    }
                                  ]}
                                >
                                  <Input
                                    placeholder="Enter button text for phone number"
                                    value={buttonPhoneTextValues[index] || ''}
                                    onChange={(e) => handleButtonPhoneTextChange(index, e.target.value)}
                                  />
                                </Form.Item>
                                <Form.Item
                                  labelAlign='left'
                                  labelCol={{span: 5}}
                                  label="Phone Number"
                                  name={['buttons', 'button_phone_number', index, 'button_phone_number']}
                                  rules={[
                                    {
                                      required: true,
                                      message: 'Please enter Phone Number',
                                    }
                                  ]}
                                >
                                  <Input/>
                                </Form.Item>
                                <Form.Item
                                  labelAlign='left'
                                  labelCol={{span: 5}}
                                  label="Button Text (URL)"
                                  name={['buttons', 'button_url', index, 'button_text_url']}
                                  rules={[
                                    {
                                      required: true,
                                      message: 'Please enter Button text for URL',
                                    },
                                    {
                                      max: 25,
                                      message: 'Body text cannot exceed 25 characters',
                                    }
                                  ]}
                                >
                                  <Input
                                    placeholder="Enter button text for url"
                                    value={buttonUrlTextValues[index] || ''}
                                    onChange={(e) => handleButtonUrlTextChange(index, e.target.value)}
                                  />
                                </Form.Item>
                                <Form.Item
                                  labelAlign='left'
                                  labelCol={{span: 5}}
                                  label="URL"
                                  name={['buttons', 'button_url', index, 'button_url']}
                                  rules={[
                                    {
                                      required: true,
                                      message: 'Please enter Website URL',
                                    }
                                  ]}
                                >
                                  <Input/>
                                </Form.Item>
                                <Form.Item
                                  labelAlign='left'
                                  labelCol={{span: 5}}
                                  label={
                                    <span>
                                              Example URL Value <InfoCircleOutlined
                                      style={{fontSize: '16px', marginLeft: '8px', cursor: 'pointer'}}
                                      onClick={() => {
                                        Modal.info({
                                          title: "Example URL Value",
                                          content: (
                                            <>
                                              <div>
                                                If you wants to add a <b>Dynamic URL</b> button then fill the <b>example
                                                url value</b>. It is mandatory for <b>Dynamic URL</b>.
                                              </div>
                                              <br/>
                                              <div style={{opacity: '0.5'}}>
                                                (Using a <b>Static URL</b>, you can give direct access via a single click
                                                to your website. You can also set up the button to visit a <b>Dynamic
                                                website</b> with your customer directed to a personalized website.)
                                              </div>
                                            </>
                                          ),
                                        });
                                      }}
                                    />
                                          </span>
                                  }
                                  name={['buttons', 'button_url', index, 'example_url_values']}
                                >

                                  <Input/>
                                </Form.Item>
                                {index > 0 && (
                                  <Button style={{marginBottom: "12px"}} type="dashed" onClick={() => removeField(index)}
                                          block icon={<MinusOutlined/>}>
                                    Remove Button
                                  </Button>
                                )}
                              </div>
                            ))}
                            {fieldsAction.length < 1 && (
                              <Button type="dashed" onClick={addField} block icon={<PlusOutlined/>}>
                                Add Button
                              </Button>
                            )}
                          </>
                        )}
                        {errorMessage && <Alert message={errorMessage} type="error" showIcon/>}
                      </Col>


                      <Col xs={24} sm={24} md={24}>
                        {actionType === 'PHONE_NUMBER' && (
                          <Form.Item labelAlign='left' labelCol={{span: 5}} label="">
                            <Form.List name={['buttons', 'button_phone_number']}
                                       initialValue={['']} // Initialize with an empty string
                            >
                              {(fields, {add, remove}) => (
                                <>
                                  {fields.map((field, index) => (
                                    <div key={field.key}>
                                      <Form.Item
                                        labelAlign='left'
                                        labelCol={{span: 5}}
                                        label="Button Text"
                                        name={[field.name, 'button_text_phone_number']}
                                        fieldKey={[field.fieldKey, 'button_text_phone_number']}
                                        rules={[
                                          {
                                            required: true,
                                            message: 'Please enter Button text',
                                          },
                                          {
                                            max: 25,
                                            message: 'Body text cannot exceed 25 characters',
                                          }
                                        ]}
                                      >
                                        <Input placeholder="Enter button text"
                                               value={buttonPhoneTextValues[index] || ''}
                                               onChange={(e) => handleButtonPhoneTextChange(index, e.target.value)}/>
                                      </Form.Item>
                                      <Form.Item
                                        labelAlign='left'
                                        labelCol={{span: 5}}
                                        label="Phone Number"
                                        name={[field.name, 'button_phone_number']}
                                        fieldKey={[field.fieldKey, 'button_phone_number']}
                                        rules={[
                                          {
                                            required: true,
                                            message: 'Please enter Phone number',
                                          }
                                        ]}
                                      >
                                        <Input/>
                                      </Form.Item>
                                      {index > 0 && (
                                        <Button
                                          style={{marginBottom: "12px"}}
                                          type="dashed"
                                          onClick={() => {
                                            remove(field.name);
                                            // Update state here to remove the corresponding button text
                                            const updatedPhoneTextValues = [...buttonPhoneTextValues];
                                            updatedPhoneTextValues.splice(index, 1);
                                            setButtonPhoneTextValues(updatedPhoneTextValues);
                                          }}
                                          block
                                          icon={<MinusOutlined/>}
                                        >
                                          Remove Button
                                        </Button>
                                      )}
                                    </div>
                                  ))}
                                  {fields.length < 2 && (
                                    <Button type="dashed" onClick={() => add()} block
                                            icon={<PlusOutlined/>}>
                                      Add Button
                                    </Button>
                                  )}
                                </>
                              )}
                            </Form.List>
                            {errorMessage && <Alert message={errorMessage} type="error" showIcon/>}
                          </Form.Item>
                        )}
                      </Col>

                      <Col xs={24} sm={24} md={24}>
                        {actionType === 'URL' && (
                          <Form.Item labelAlign='left' labelCol={{span: 5}} label="">
                            <Form.List name={['buttons', 'button_url']}
                                       initialValue={['']} // Initialize with an empty string
                            >
                              {(fields, {add, remove}) => (
                                <>
                                  {fields.map((field, index) => (
                                    <div key={field.key}>
                                      <Form.Item
                                        labelAlign='left'
                                        labelCol={{span: 5}}
                                        label="Button Text"
                                        name={[field.name, 'button_text_url']}
                                        fieldKey={[field.fieldKey, 'button_text_url']}
                                        rules={[
                                          {
                                            required: true,
                                            message: 'Please enter Button text',
                                          },
                                          {
                                            max: 25,
                                            message: 'Body text cannot exceed 25 characters',
                                          }
                                        ]}
                                      >
                                        <Input placeholder="Enter button text"
                                               value={buttonUrlTextValues[index] || ''}
                                               onChange={(e) => handleButtonUrlTextChange(index, e.target.value)}/>
                                      </Form.Item>
                                      <Form.Item
                                        labelAlign='left'
                                        labelCol={{span: 5}}
                                        label="URL"
                                        name={[field.name, 'button_url']}
                                        fieldKey={[field.fieldKey, 'button_url']}
                                        rules={[
                                          {
                                            required: true,
                                            message: 'Please enter Button URL value',
                                          }
                                        ]}
                                      >
                                        <Input/>
                                      </Form.Item>
                                      <Form.Item
                                        labelAlign='left'
                                        labelCol={{span: 5}}
                                        label={
                                          <span>
                                              Example URL Value <InfoCircleOutlined
                                            style={{fontSize: '16px', marginLeft: '8px', cursor: 'pointer'}}
                                            onClick={() => {
                                              Modal.info({
                                                title: "Example URL Value",
                                                content: (
                                                  <>
                                                    <div>
                                                      If you wants to add a <b>Dynamic URL</b> button then fill the <b>example
                                                      url value</b>. It is mandatory for <b>Dynamic URL</b>.
                                                    </div>
                                                    <br/>
                                                    <div style={{opacity: '0.5'}}>
                                                      (Using a <b>Static URL</b>, you can give direct access via a single
                                                      click to your website. You can also set up the button to visit a <b>Dynamic
                                                      website</b> with your customer directed to a personalized website.)
                                                    </div>
                                                  </>
                                                ),
                                              });
                                            }}
                                          />
                                          </span>
                                        }
                                        name={[field.name, 'example_url_values']}
                                        fieldKey={[field.fieldKey, 'example_url_values']}
                                      >

                                        <Input/>
                                      </Form.Item>
                                      {index > 0 && (
                                        <Button
                                          style={{marginBottom: "12px"}}
                                          type="dashed"
                                          onClick={() => {
                                            remove(field.name);
                                            // Update state here to remove the corresponding button text
                                            const updatedUrlTextValues = [...buttonUrlTextValues];
                                            updatedUrlTextValues.splice(index, 1);
                                            setButtonUrlTextValues(updatedUrlTextValues);
                                          }}
                                          block
                                          icon={<MinusOutlined/>}
                                        >
                                          Remove Button
                                        </Button>
                                      )}
                                    </div>
                                  ))}
                                  {fields.length < 2 && (
                                    <Button type="dashed" onClick={() => add()} block
                                            icon={<PlusOutlined/>}>
                                      Add Button
                                    </Button>
                                  )}
                                </>
                              )}
                            </Form.List>
                            {errorMessage && <Alert message={errorMessage} type="error" showIcon/>}
                          </Form.Item>
                        )}
                      </Col>
                    </Col>
                  )}
                </Row>
              )}
              <Form.Item>
                <div className="mt-4 text-right">
                  {currentStep === 1 && (
                    <Button type="primary" onClick={onNextStep}>
                      Next
                    </Button>
                  )}
                  {currentStep === 2 && (
                    <Col>
                      <Button onClick={() => setCurrentStep(1)}
                              style={{marginRight: "12px"}}>Back</Button>
                      <Button type="primary" onClick={onNextStep}>
                        Next
                      </Button>
                    </Col>
                  )}
                  {currentStep === 3 && (
                    <Col>
                      <Button onClick={() => setCurrentStep(2)}
                              style={{marginRight: "12px"}}>Back</Button>
                      <Button type="primary" onClick={onNextStep} loading={loading}>
                        Save
                      </Button>
                    </Col>
                  )}
                </div>
              </Form.Item>
            </Form>
          </Col>
          <Col xs={1} sm={1} md={1}>
            {/*  */}
          </Col>
          {currentStep !== 1 && (
            <Col xs={5} sm={5} md={5} style={chatContainerStyle}>
              <ChatPreviewPage headerText={headerText} bodyText={bodyText} footerText={footerText}
                               selectedMediaType={headerFormat} buttonType={buttonType}
                               actionType={actionType} actionButtonsQuickReply={buttonTextValues}
                               actionButtonsPhoneNumber={buttonPhoneTextValues}
                               actionButtonsUrl={buttonUrlTextValues}/>
            </Col>)}
        </Row>

      </Modal>
    );
  }
;

WhatsappTemplateForm.defaultProps = {
  onSave: undefined,
  onClose: undefined,
  editItem: undefined
};

export default WhatsappTemplateForm;
