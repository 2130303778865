import React, { useEffect, useState } from 'react';
import InnerAppPageLayout from 'layouts/innerApp';
import { Menu } from 'antd';
import { BellOutlined, CreditCardOutlined, LockOutlined, UserOutlined } from '@ant-design/icons';
// import { Link, Navigate, Route, Routes, Outlet, useLocation, useMatch } from 'react-router-dom';
import { Link, Outlet, useLocation } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { RootState } from 'App';
import { AuthState } from "models";


const SettingOption = () => {

  const { user } = useSelector((state: RootState): AuthState => state.auth);
  const [isSubUser, setIsSubUser] = useState(false);
  const [isSubAccount, setIsSubAccount] = useState(false);


  useEffect(() => {
    const subUser = user?.account_type === 'SUB_USER';
    const subAccount = user?.account_type === 'SUB_ACCOUNT';
    setIsSubAccount(subAccount);
    setIsSubUser(subUser);
  }, [user]);

  const location = useLocation();
  return (
    <Menu
      mode="inline"
      defaultSelectedKeys={[`/account`]}
      selectedKeys={[location.pathname]}
    >
      <Menu.Item key="/users/preference/account">
        <UserOutlined />
        <span>Account Preference</span>
        <Link to="account" />
      </Menu.Item>
      <Menu.Item key="/users/preference/change-password">
        <LockOutlined />
        <span>Change Password</span>
        <Link to="change-password" />
      </Menu.Item>

    {/* <Menu.Item key="/users/preference/security">
        <LockOutlined />
        <span>Security</span>
        <Link to="security" />
      </Menu.Item> */}

      <Menu.Item key="/users/preference/sub-users" hidden={isSubUser}>
        <CreditCardOutlined />
        <span>Sub Users</span>
        <Link to="sub-users" />
      </Menu.Item>
      <Menu.Item key="/users/preference/sub-accounts" hidden={isSubUser || isSubAccount}>
        <BellOutlined />
        <span>Sub Accounts</span>
        <Link to="sub-accounts" />
      </Menu.Item>
    </Menu>
  );
};
const UserPreference = () => (
  <InnerAppPageLayout
    sideContentWidth={320}
    sideContent={<SettingOption />}
    mainContent={<Outlet />} />
);

export default UserPreference;
