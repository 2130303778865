import { DeleteOutlined } from "@ant-design/icons";
import { Dropdown, Form, Input, List, Menu, notification, Spin, Upload } from "antd";
import { FormInstance } from "antd/es/form";
import useBreakpoint from "antd/lib/grid/hooks/useBreakpoint";
import IconSvg from "components/utils/IconSvg";
import Picker from "emoji-picker-react";
import React, { ChangeEvent, useEffect, useRef, useState } from "react";
import { Link } from "react-router-dom";
import Utils from "utils";

interface ChatInputProps {
  showEmojis: boolean;
  setShowEmojis: (show: boolean) => void;
  newMessage: string;
  setNewMessage: (message: string) => void;
  submitNewMessage: () => void;
  formRef: React.RefObject<FormInstance>;
  isSessionExpired: any;
  fileList: any[];
  setFileList: any;
  isFileUploading: boolean;
}

const ChatInput: React.FC<ChatInputProps> = ({
  showEmojis,
  setShowEmojis,
  newMessage,
  setNewMessage,
  submitNewMessage,
  formRef,
  isSessionExpired,
  fileList,
  setFileList,
  isFileUploading,
}) => {
  const [selectionStart, setSelectionStart] = useState<number | null>(null);
  const [selectionEnd, setSelectionEnd] = useState<number | null>(null);
  const [showAttach, setShowAttach] = useState(false);

  const emojiPickerRef = useRef<HTMLDivElement>(null);
  const isMobile = Utils.getBreakPoint(useBreakpoint()).includes('xs');

  const detectEnterPress = (e: React.KeyboardEvent<HTMLInputElement>): void => {
    if (e.key === "Enter" || e.keyCode === 13) {
      submitNewMessage();
    }
  };

  const handleSelect = (e: ChangeEvent<HTMLInputElement>) => {
    setSelectionStart(e.target.selectionStart);
    setSelectionEnd(e.target.selectionEnd);
  };

  const handleEmojiClick = (emojiObject: any) => {
    const { emoji } = emojiObject;
    const newText = `${newMessage.slice(
      0,
      selectionStart ?? undefined
    )}${emoji}${newMessage.slice(selectionEnd ?? undefined)}`;
    setNewMessage(newText);
    formRef?.current?.setFieldsValue({ newMsg: newText });
  };

  const handleClickOutside = (event: MouseEvent) => {
    if (
      emojiPickerRef.current &&
      !emojiPickerRef.current.contains(event.target as Node)
    ) {
      setShowEmojis(false);
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [isSessionExpired]);


  const onChangeFile = (info: any) => {
    setFileList(info.fileList);
  };


  const renderUploadFormItem = (id : string, item: any, accept: any) => (
     <Upload
        name="file"
        accept={accept}
        maxCount={1}
        onChange={onChangeFile}
        fileList={[]}
        showUploadList={{showRemoveIcon: true, removeIcon: <DeleteOutlined/>}}
        beforeUpload={() => false}
        className="chat__input-file-upload"
      >
        {item}
      </Upload>
  );

  const attachMenuOptions = [
    {
      id: "attachDocument",
      label: "Documents",
      icon: "attachDocument",
      accept: ".txt, .xls, .xlsx, .doc, .docx, .ppt, .pptx, .pdf",
      render: renderUploadFormItem,
    },
    {
      id: "attachImage",
      label: "Photos & Videos",
      icon: "attachImage",
      accept: ".jpeg, .png, .mp4, .3gp",
      render: renderUploadFormItem,
    },
    {
      id: "attachCamera",
      label: "Camera",
      icon: "attachCamera",
      hidden: true,
      onClick : () => notification.info({
        message: 'Coming soon',
        description: "We are working on adding camera functionality.",
      }),
    },
    {
      id: "attachContacts",
      label: "Contact",
      icon: "attachContacts",
      hidden: true,
      onClick : () => notification.info({
        message: 'Coming soon',
        description: "We are working on adding contact functionality.",
      }),
    },
    {
      id: "attachAudio",
      label: "Audio",
      icon: "attachAudio",
      accept: ".aac, .amr, .mp3,.m4a,.ogg",
      render: renderUploadFormItem,
    },
  ];


  const attachMenu = (
    <Menu>
      {attachMenuOptions?.map((attachItem) => {
        const menuItem = (
          <Menu.Item
            key={attachItem.label}
            style={{ padding: "10px 16px", paddingRight: "3rem" }}
            hidden={attachItem?.hidden}
            onClick={() => {setShowAttach(false); if(attachItem?.onClick) attachItem?.onClick();}}
          >
            <div style={{ display: "flex", alignItems: "center" }}>
              <span style={{ width: "30px", height: "20px" }}>
                <IconSvg id={attachItem.icon} className="chat__input-icon" />
              </span>
              <span style={{ fontSize: "16px" }}>{attachItem.label}</span>
            </div>
          </Menu.Item>
        );
        return attachItem?.render
          ? attachItem?.render(attachItem.id, menuItem, attachItem.accept)
          : menuItem;
      })}
    </Menu>
  );

  const handleRemove = (file: any) => {
    setFileList((prevList:any) => prevList.filter((item: any) => item.uid !== file.uid));
  };



  return (
    <>
         {fileList.length > 0 && <List
          className="chat__input-file-list"
          bordered
          dataSource={fileList}
          renderItem={(item: any) => (
            <List.Item
              key={item.uid}
              actions={[<DeleteOutlined key="delete" onClick={() => handleRemove(item)} />]}
              extra={<Spin size="small" spinning={isFileUploading} />}
            >
              <List.Item.Meta
                key={item.uid}
                // avatar={<Image width={50} src={item.thumbUrl || item.url} />}
                title={item.name}
              />
            </List.Item>
          )}
        />}

    <div style={{display: "flex", gap: "0.5rem", alignItems: "center"}}>
      <div className="chat__input-wrapper" style={{flexGrow: "1"}}>
      <button
        type="button"
        aria-label="Emojis"
        onClick={() => setShowEmojis(!showEmojis)}
        style={{ display: "flex", alignItems: "center", cursor: isSessionExpired ? "not-allowed" :"pointer" }}
        disabled={isSessionExpired}
      >
        <IconSvg
          id="smiley"
          className={`chat__input-icon ${
            showEmojis ? "chat__input-icon--highlight" : ""
          }`}
        />
      </button>
      <Dropdown overlay={attachMenu} trigger={["click"]} visible={showAttach} onVisibleChange={setShowAttach} disabled={isSessionExpired}>
      <button
       type="button"
       aria-label="Attach"
      //  onClick={() => setShowAttach(!showAttach)}
       style={{ display: "flex", alignItems: "center", cursor: isSessionExpired ? "not-allowed" :"pointer" }}
        disabled={isSessionExpired}>
					<IconSvg
						id="attach"
						className={`chat__input-icon ${showAttach ? "chat__input-icon--highlight" : ""}`}
					/>
				</button>
        </Dropdown>


      <Form name="msgInput" ref={formRef} className="w-100">
        <Form.Item name="newMsg" className="mb-0">
          {isSessionExpired ? (
            <div className="chat__input session-expired-message">
              {isMobile ? <span>Send a <Link type="link" to="/whatsapp-meta/whatsapp-campaign">template message.</Link></span>
              : <span>WhatsApp lets you to message freely after a customer messages you. You can use <Link type="link" to="/whatsapp-meta/whatsapp-campaign">template messages to send messages</Link> to the customer anytime.</span>}
            </div>
          ) : (
            <Input
              className="chat__input"
              autoComplete="off"
              placeholder={fileList.length > 0 ? "Write a caption..." : "Type a message..."}
              onChange={(e) => setNewMessage(e.target.value)}
              onKeyDown={detectEnterPress}
              value={newMessage}
              onSelect={handleSelect}
              disabled={isSessionExpired}
            />
          )}
        </Form.Item>
      </Form>
      </div>
      <button
        type="button"
        aria-label="Send message"
        onClick={(newMessage || fileList.length > 0) && !isSessionExpired ? submitNewMessage : undefined}
        style={{ cursor: isSessionExpired ? "not-allowed" : "pointer", marginRight: "0.8rem" }}
        disabled={isSessionExpired}
      >
        <IconSvg
          id="send"
          className={`chat__input-icon ${
            (newMessage || fileList.length > 0) ? "chat__input-icon--highlight" : ""
          }`}
        />
        {/* <IconSvg
          id="microphone"
          className={`chat__input-icon ${
            (newMessage || fileList.length > 0) ? "chat__input-icon--highlight" : ""
          }
          `}
        /> */}
      </button>

      {showEmojis && !isSessionExpired && (
        <div
          style={{ position: "absolute", bottom: "70px", left: "16px" }}
          ref={emojiPickerRef}
        >
          <Picker onEmojiClick={handleEmojiClick} />
        </div>
      )}
    </div>
    </>
  );
};

export default ChatInput;
